import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@models/app-settings.model';
import { LocaleData } from '@models/locale-data.model';
import { constants } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  private settings = {} as AppSettings;
  private localeData = {} as LocaleData;
  private _baseUrl = '';

  constructor(private http: HttpClient) {}

  discover(): Promise<void> {
    const urlPaths = window.location.pathname.split('/');
    let metadataToken = sessionStorage.getItem(constants.metadataToken);

    if (urlPaths.some((x) => x.toLocaleLowerCase() == 'activate')) {
      metadataToken = urlPaths[urlPaths.length - 1];
    }

    return this.http
      .get(
        `${this.settings.accmngApiUrl}v1/invitations/discover?metadataToken=${metadataToken}`
      )
      .toPromise()
      .then((res: any) => {
        sessionStorage.setItem(constants.baseUrl, `${res}/api/`);
        this.baseUrl = `${res}/api/`;
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  loadAppSettings(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const useMock = urlParams.get('usemock');

    return this.http
      .get(
        useMock
          ? '/assets/config/app.settings.mock.json'
          : '/assets/config/app.settings.json'
      )
      .toPromise()
      .then((data: any) => {
        this.settings = { ...data, accmngApiUrl: `${data.accmngApiUrl}/api/` };
      });
  }

  loadAppLocaleData(): Promise<void> {
    return this.http
      .get('/assets/locale/locale.json')
      .toPromise()
      .then((data: any) => {
        this.localeData = data;
      });
  }

  get appSettings(): AppSettings {
    if (!this.settings) {
      throw Error('App Settings not loaded!');
    }
    return this.settings;
  }

  get appLocaleData(): LocaleData {
    if (!this.localeData) {
      throw Error('Locale Data not loaded');
    }

    return this.localeData;
  }

  get baseUrl() {
    return this._baseUrl;
  }

  set baseUrl(value: string) {
    this._baseUrl = value;
  }
}
